<template>
    <el-row v-if="item_condition" :gutter="12">
        <el-col :span="2">
            <div
                v-if="index === 0"
                class="bg-[#f8edbe] util-flex-center rounded-r-lg h-8"
            >
                <p class="text-sm">
                    {{ $t('common.if') }}
                </p>
            </div>
            <div v-else class="bg-[#ebebeb] util-flex-center rounded-r-lg h-8">
                <p class="text-sm">{{ $t('common.and') }}</p>
            </div>
        </el-col>

        <el-col :span="showSpanSubjectType()">
            <el-select
                v-model="item_condition.subject_type"
                class="w-full"
                size="small"
            >
                <el-option-group
                    v-for="group in subject_type_options"
                    :key="group.label"
                    :label="group.label"
                >
                    <el-option
                        v-for="item in group.options"
                        :key="item.value"
                        :disabled="
                            list_selected_subject_type.some(
                                (i) => i == item.value
                            )
                        "
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-option-group>
            </el-select>
        </el-col>
        <el-col v-if="showCalculationType()" :span="3">
            <el-select
                v-model="item_condition.calculation_type"
                class="w-full"
                size="small"
            >
                <el-option
                    v-for="item in calculation_type_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
        </el-col>
        <el-col v-if="showRangeType()" :span="3">
            <el-select
                v-model="item_condition.range_type"
                class="w-full"
                size="small"
            >
                <el-option
                    v-for="item in range_type_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
        </el-col>

        <el-col :span="3">
            <el-select
                v-model="item_condition.match_type"
                class="w-full"
                size="small"
                :disabled="disableMatchType()"
            >
                <el-option
                    v-for="item in match_type_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
        </el-col>
        <el-col :span="8">
            <el-select
                v-if="showSelectObjectiveTypes()"
                v-model="value_condition_for_select"
                size="small"
                class="w-full"
            >
                <el-option
                    v-for="item in objective_type_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>

            <el-select
                v-else-if="showSelectBidStrategyTypes()"
                v-model="value_condition_for_select"
                size="small"
                class="w-full"
            >
                <el-option
                    v-for="item in bid_strategy_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>

            <el-select
                v-else-if="showSelectOptimizationGoalTypes()"
                v-model="value_condition_for_select"
                size="small"
                class="w-full"
            >
                <el-option
                    v-for="item in optimization_goal_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>

            <div
                v-else-if="showInputRange()"
                class="flex items-center space-x-4"
            >
                <el-input v-model="value_condition" size="small" type="number">
                    <!-- <template slot="append">
                        {{ renderAppendInputValue() }}
                    </template> -->
                </el-input>
                <p>-</p>
                <el-input
                    v-model="value_condition_2"
                    size="small"
                    type="number"
                >
                    <!-- <template slot="append">
                        {{ renderAppendInputValue() }}
                    </template> -->
                </el-input>
            </div>

            <el-input
                v-else-if="showInputName()"
                v-model="value_condition_for_input_name"
                size="small"
            >
            </el-input>

            <el-input
                v-else
                v-model="value_condition"
                type="number"
                size="small"
            >
                <!-- <template slot="append">
                    {{ renderAppendInputValue() }}
                </template> -->
            </el-input>
        </el-col>
        <el-col :span="2">
            <i
                v-if="selected_conditions.length > 1"
                class="el-icon-delete mt-2 cursor-pointer"
                @click="handle_remove(index)"
            ></i>
        </el-col>
    </el-row>
</template>

<script>
export default {
    props: [
        'selected_conditions',
        'index',
        'handle_remove',
        'dimension',
        'currency',
        'list_selected_subject_type'
    ],

    data() {
        return {
            value_condition: 0,
            value_condition_2: 0,
            value_condition_for_input_name: '',
            value_condition_for_select: '',
            item_condition: null,
            objective_type_options: [
                {
                    value: 'TRAFFIC',
                    label: this.$t('common.traffic')
                },
                {
                    value: 'APP_INSTALL',
                    label: this.$t('page.campaign.column_app_install')
                },
                {
                    value: 'CONVERSIONS',
                    label: this.$t('common.conversion')
                },
                {
                    value: 'VIDEO_VIEWS',
                    label: this.$t('page.campaign.video_views')
                },
                {
                    value: 'REACH',
                    label: this.$t('common.reach')
                },
                {
                    value: 'LEAD_GENERATION',
                    label: this.$t('page.campaign.lead_generation')
                }
            ],
            bid_strategy_options: [
                {
                    value: 'COST_CAP',
                    label: this.$t('page.campaign.bid_strategy_bid_cap')
                },
                {
                    value: 'BID_CAP',
                    label: this.$t('page.campaign.bid_strategy_bid_cap')
                },
                // {
                //     value: 'MAX_CONVERSION',
                //     label: 'MAX_CONVERSION'
                // },
                {
                    value: 'LOWEST_COST',
                    label: this.$t('page.campaign.bid_strategy_lowest_cost')
                }
            ],
            optimization_goal_options: [
                {
                    value: 'CONVERT',
                    label: this.$t('page.campaign.type_live_ads.convert')
                },
                {
                    value: 'CLICK',
                    label: this.$t('common.click')
                },
                {
                    value: 'SHOW',
                    label: this.$t('common.show')
                },
                {
                    value: 'REACH',
                    label: this.$t('common.reach')
                },
                {
                    value: 'INSTALL',
                    label: this.$t('page.campaign.install')
                },
                {
                    value: 'IN_APP_EVENT',
                    label: this.$t('page.campaign.custom_column_in_app_event')
                },
                {
                    value: 'LEAD_GENERATION',
                    label: this.$t('page.campaign.lead_generation')
                },
                {
                    value: 'TWO_SECOND_VIDEO_VIEW',
                    label: this.$t('page.campaign.column_video_watched_2s')
                },
                {
                    value: 'SIX_SECOND_VIDEO_VIEW',
                    label: this.$t('page.campaign.column_video_watched_6s')
                }
            ],
            range_type_options: [],
            match_type_options: [],
            calculation_type_options: [
                {
                    value: 'ALL_OBJECTS',
                    label: this.$t('page.campaign.all_objects')
                },
                {
                    value: 'OF_EACH_OBJECT',
                    label: this.$t('page.campaign.of_each_object')
                }
            ],
            subject_type_options: [],
            subject_type_show_9_spans: [
                'OBJECTIVE',
                'BID_STRATEGY',
                'OPTIMIZATION_GOAL',
                'NAME',
                'DAYS_SINCE_CREATION'
            ],
            subject_type_show_6_spans: [
                'cost_per_conversion',
                'cpm',
                'cpc',
                'conversion',
                'conversion_rate',
                'impressions',
                'clicks',
                'ctr'
            ]
        }
    },

    computed: {
        selected_conditions_in_line: {
            get() {
                return this.selected_conditions
            },
            set(value) {
                this.$emit('update:selected_conditions', value)
            }
        }
    },

    watch: {
        'item_condition.subject_type'() {
            // this.value_condition = '1'
            this.item_condition.match_type = 'GT'
            this.item_condition.range_type = 'TODAY'
            this.item_condition.calculation_type = 'OF_EACH_OBJECT'

            this.match_type_options = [
                {
                    value: 'GT',
                    label: this.$t('common.greater_than')
                },
                {
                    value: 'LT',
                    label: this.$t('common.less_than')
                },
                {
                    value: 'BETWEEN',
                    label: this.$t('common.between')
                }
            ]

            this.range_type_options = [
                {
                    value: 'TODAY',
                    label: this.$t('common.today')
                },
                {
                    value: 'YESTERDAY',
                    label: this.$t('common.yesterday')
                },
                {
                    value: 'PAST_THREE_DAYS',
                    label: this.$t('common.past_xxx_days', {
                        xxx: 3
                    })
                },
                {
                    value: 'PAST_FIVE_DAYS',
                    label: this.$t('common.past_xxx_days', {
                        xxx: 5
                    })
                },
                {
                    value: 'PAST_SEVEN_DAYS',
                    label: this.$t('common.past_xxx_days', {
                        xxx: 7
                    })
                },
                {
                    value: 'PAST_THIRTY_DAYS',
                    label: this.$t('common.past_xxx_days', {
                        xxx: 30
                    })
                },
                {
                    value: 'LIFETIME',
                    label: this.$t('common.lifetime')
                }
            ]

            if (
                [
                    ...this.subject_type_show_6_spans,
                    ...this.subject_type_show_9_spans
                ].some((item) => item === this.item_condition.subject_type)
            ) {
                this.item_condition.calculation_type = ''
            }

            if (this.item_condition.subject_type === 'OBJECTIVE') {
                this.item_condition.match_type = 'MATCH'
                this.value_condition_for_select =
                    this.objective_type_options[0].value
                this.match_type_options = [
                    {
                        value: 'MATCH',
                        label: this.$t('common.equal')
                    }
                ]
                return
            }

            if (this.item_condition.subject_type === 'BID_STRATEGY') {
                this.item_condition.match_type = 'MATCH'
                this.value_condition_for_select =
                    this.bid_strategy_options[0].value

                this.match_type_options = [
                    {
                        value: 'MATCH',
                        label: this.$t('common.equal')
                    }
                ]

                return
            }

            if (this.item_condition.subject_type === 'OPTIMIZATION_GOAL') {
                this.item_condition.match_type = 'MATCH'
                this.value_condition_for_select =
                    this.optimization_goal_options[0].value

                this.match_type_options = [
                    {
                        value: 'MATCH',
                        label: this.$t('common.equal')
                    }
                ]

                return
            }

            if (this.item_condition.subject_type === 'NAME') {
                this.item_condition.match_type = 'CONTAINS'
                // this.value_condition_for_input_name = ''

                this.match_type_options = [
                    {
                        value: 'CONTAINS',
                        label: this.$t('common.contains')
                    },
                    {
                        value: 'NOT_CONTAINS',
                        label: this.$t('common.not_contains')
                    },
                    {
                        value: 'START_WITH',
                        label: this.$t('common.start_with')
                    },
                    {
                        value: 'END_WITH',
                        label: this.$t('common.end_with')
                    },
                    {
                        value: 'STRING_EQUAL',
                        label: this.$t('common.string_equal')
                    }
                ]

                return
            }

            if (this.item_condition.subject_type === 'DAYS_SINCE_CREATION') {
                this.match_type_options = [
                    {
                        value: 'GT',
                        label: this.$t('common.greater_than')
                    },
                    {
                        value: 'LT',
                        label: this.$t('common.less_than')
                    },
                    {
                        value: 'BETWEEN',
                        label: this.$t('common.between')
                    },
                    {
                        value: 'MATCH',
                        label: this.$t('common.equal')
                    }
                ]

                return
            }

            if (
                this.item_condition.subject_type ===
                'DAILY_BUDGET_SPENDING_RATE'
            ) {
                this.range_type_options = [
                    {
                        value: 'TODAY',
                        label: this.$t('common.today')
                    }
                ]

                return
            }

            if (
                this.item_condition.subject_type ===
                'LIFETIME_BUDGET_SPENDING_RATE'
            ) {
                this.item_condition.range_type = 'LIFETIME'

                this.range_type_options = [
                    {
                        value: 'LIFETIME',
                        label: this.$t('common.lifetime')
                    }
                ]

                return
            }
        },

        // 'item_condition.match_type'() {
        //     if (this.item_condition.match_type === 'BETWEEN') {
        //         this.value_condition = 1
        //         this.value_condition_2 = 2
        //     } else {
        //         this.value_condition = 1
        //     }
        // },

        value_condition_for_select() {
            this.item_condition.values = [this.value_condition_for_select]
        },

        value_condition_for_input_name() {
            this.item_condition.values = [this.value_condition_for_input_name]
        },

        value_condition() {
            if (this.item_condition.match_type === 'BETWEEN') {
                this.item_condition.values = [
                    this.value_condition,
                    this.value_condition_2
                ]
            } else {
                this.item_condition.values = [this.value_condition]
            }
        },

        value_condition_2() {
            if (this.item_condition.match_type === 'BETWEEN') {
                this.item_condition.values = [
                    this.value_condition,
                    this.value_condition_2
                ]
            } else {
                this.item_condition.values = [this.value_condition]
            }
        },

        dimension() {
            this.handleChangeSubjectTypeByDimension()
        },

        selected_conditions_in_line() {
            this.item_condition = this.selected_conditions_in_line[this.index]
        }
    },

    mounted() {
        this.handleChangeSubjectTypeByDimension()

        this.item_condition = this.selected_conditions_in_line[this.index]
    },

    methods: {
        handleChangeSubjectTypeByDimension() {
            const temp = [
                {
                    label: this.$t('page.campaign.custom_column_basic_data'),
                    options: [
                        {
                            value: 'spend',
                            label: this.$t('common.cost')
                        },
                        {
                            value: 'cost_per_conversion',
                            label: this.$t(
                                'page.campaign.column_cost_per_conversion'
                            )
                        },
                        {
                            value: 'cpm',
                            label: this.$t('page.campaign.column_cpm')
                        },
                        {
                            value: 'cpc',
                            label: this.$t('page.campaign.column_cpc')
                        },
                        {
                            value: 'conversion',
                            label: this.$t('page.campaign.column_conversion')
                        },
                        {
                            value: 'conversion_rate',
                            label: this.$t(
                                'page.campaign.column_conversion_rate'
                            )
                        },
                        {
                            value: 'impressions',
                            label: this.$t('page.campaign.column_impressions')
                        },
                        {
                            value: 'clicks',
                            label: this.$t('page.campaign.column_clicks')
                        },
                        {
                            value: 'ctr',
                            label: this.$t('page.campaign.column_ctr')
                        }
                    ]
                },
                {
                    label: this.$t('page.campaign.custom_column_video_play'),
                    options: [
                        {
                            label: this.$t(
                                'page.campaign.column_video_play_actions'
                            ),
                            value: 'video_play_actions'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_video_watched_2s'
                            ),
                            value: 'video_watched_2s'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_video_watched_6s'
                            ),
                            value: 'video_watched_6s'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_average_video_play'
                            ),
                            value: 'average_video_play'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_average_video_play_per_user'
                            ),
                            value: 'average_video_play_per_user'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_video_views_p25'
                            ),
                            value: 'video_views_p25'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_video_views_p50'
                            ),
                            value: 'video_views_p50'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_video_views_p75'
                            ),
                            value: 'video_views_p75'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_video_views_p100'
                            ),
                            value: 'video_views_p100'
                        },
                        {
                            label: this.$t('page.campaign.column_engaged_view'),
                            value: 'engaged_view'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_engaged_view_15s'
                            ),
                            value: 'engaged_view_15s'
                        }
                    ]
                },
                {
                    label: this.$t('page.campaign.custom_column_engagement'),
                    options: [
                        {
                            label: this.$t('page.campaign.column_engagements'),
                            value: 'engagements'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_engagement_rate'
                            ),
                            value: 'engagement_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_profile_visits'
                            ),
                            value: 'profile_visits'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_profile_visits_rate'
                            ),
                            value: 'profile_visits_rate'
                        },
                        {
                            label: this.$t('page.campaign.column_likes'),
                            value: 'likes'
                        },
                        {
                            label: this.$t('page.campaign.column_comments'),
                            value: 'comments'
                        },
                        {
                            label: this.$t('page.campaign.column_shares'),
                            value: 'shares'
                        },
                        {
                            label: this.$t('page.campaign.column_follows'),
                            value: 'follows'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_clicks_on_music_disc'
                            ),
                            value: 'clicks_on_music_disc'
                        },
                        {
                            label: this.$t('page.campaign.column_duet_clicks'),
                            value: 'duet_clicks'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_stitch_clicks'
                            ),
                            value: 'stitch_clicks'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_sound_usage_clicks'
                            ),
                            value: 'sound_usage_clicks'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_anchor_clicks'
                            ),
                            value: 'anchor_clicks'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_anchor_click_rate'
                            ),
                            value: 'anchor_click_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_clicks_on_hashtag_challenge'
                            ),
                            value: 'clicks_on_hashtag_challenge'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_page_duration_avg'
                            ),
                            value: 'ix_page_duration_avg'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_page_viewrate_avg'
                            ),
                            value: 'ix_page_viewrate_avg'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_video_views'
                            ),
                            value: 'ix_video_views'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_video_views_p25'
                            ),
                            value: 'ix_video_views_p25'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_video_views_p50'
                            ),
                            value: 'ix_video_views_p50'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_video_views_p75'
                            ),
                            value: 'ix_video_views_p75'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_video_views_p100'
                            ),
                            value: 'ix_video_views_p100'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_average_video_play'
                            ),
                            value: 'ix_average_video_play'
                        }
                    ]
                },
                {
                    label: this.$t(
                        'page.campaign.custom_column_interactive_add_on'
                    ),
                    options: [
                        {
                            label: this.$t(
                                'page.campaign.column_interactive_add_on_impressions'
                            ),
                            value: 'interactive_add_on_impressions'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_interactive_add_on_destination_clicks'
                            ),
                            value: 'interactive_add_on_destination_clicks'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_interactive_add_on_activity_clicks'
                            ),
                            value: 'interactive_add_on_activity_clicks'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_interactive_add_on_option_a_clicks'
                            ),
                            value: 'interactive_add_on_option_a_clicks'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_interactive_add_on_option_b_clicks'
                            ),
                            value: 'interactive_add_on_option_b_clicks'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_countdown_sticker_recall_clicks'
                            ),
                            value: 'countdown_sticker_recall_clicks'
                        }
                    ]
                },
                {
                    label: this.$t('page.campaign.custom_column_live'),
                    value: 'LIVE',
                    options: [
                        {
                            label: this.$t('page.campaign.column_live_views'),
                            value: 'live_views'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_live_unique_views'
                            ),
                            value: 'live_unique_views'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_live_effective_views'
                            ),
                            value: 'live_effective_views'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_live_product_clicks'
                            ),
                            value: 'live_product_clicks'
                        }
                    ]
                },
                {
                    label: this.$t('page.campaign.custom_column_in_app_event'),
                    value: 'IN_APP_EVENT',
                    options: [
                        {
                            label: this.$t(
                                'page.campaign.column_real_time_app_install'
                            ),
                            value: 'real_time_app_install'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_real_time_app_install_cost'
                            ),
                            value: 'real_time_app_install_cost'
                        },
                        {
                            label: this.$t('page.campaign.column_app_install'),
                            value: 'app_install'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_app_install'
                            ),
                            value: 'cost_per_app_install'
                        },
                        {
                            label: this.$t('page.campaign.column_registration'),
                            value: 'registration'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_registration'
                            ),
                            value: 'cost_per_registration'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_registration_rate'
                            ),
                            value: 'registration_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_registration'
                            ),
                            value: 'total_registration'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_registration'
                            ),
                            value: 'cost_per_total_registration'
                        },
                        {
                            label: this.$t('page.campaign.column_purchase'),
                            value: 'purchase'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_purchase'
                            ),
                            value: 'cost_per_purchase'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_purchase_rate'
                            ),
                            value: 'purchase_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_purchase'
                            ),
                            value: 'total_purchase'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_purchase'
                            ),
                            value: 'cost_per_total_purchase'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_purchase'
                            ),
                            value: 'value_per_total_purchase'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_purchase_value'
                            ),
                            value: 'total_purchase_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_active_pay_roas'
                            ),
                            value: 'total_active_pay_roas'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_app_event_add_to_cart'
                            ),
                            value: 'app_event_add_to_cart'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_app_event_add_to_cart'
                            ),
                            value: 'cost_per_app_event_add_to_cart'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_app_event_add_to_cart_rate'
                            ),
                            value: 'app_event_add_to_cart_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_app_event_add_to_cart'
                            ),
                            value: 'total_app_event_add_to_cart'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_app_event_add_to_cart'
                            ),
                            value: 'cost_per_total_app_event_add_to_cart'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_app_event_add_to_cart'
                            ),
                            value: 'value_per_total_app_event_add_to_cart'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_app_event_add_to_cart_value'
                            ),
                            value: 'total_app_event_add_to_cart_value'
                        },
                        {
                            label: this.$t('page.campaign.column_checkout'),
                            value: 'checkout'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_checkout'
                            ),
                            value: 'cost_per_checkout'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_checkout_rate'
                            ),
                            value: 'checkout_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_checkout'
                            ),
                            value: 'total_checkout'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_checkout'
                            ),
                            value: 'cost_per_total_checkout'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_checkout'
                            ),
                            value: 'value_per_checkout'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_checkout_value'
                            ),
                            value: 'total_checkout_value'
                        },
                        {
                            label: this.$t('page.campaign.column_view_content'),
                            value: 'view_content'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_view_content'
                            ),
                            value: 'cost_per_view_content'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_view_content_rate'
                            ),
                            value: 'view_content_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_view_content'
                            ),
                            value: 'total_view_content'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_view_content'
                            ),
                            value: 'cost_per_total_view_content'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_view_content'
                            ),
                            value: 'value_per_total_view_content'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_view_content_value'
                            ),
                            value: 'total_view_content_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_next_day_open'
                            ),
                            value: 'next_day_open'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_next_day_open'
                            ),
                            value: 'cost_per_next_day_open'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_next_day_open_rate'
                            ),
                            value: 'next_day_open_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_next_day_open'
                            ),
                            value: 'total_next_day_open'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_next_day_open'
                            ),
                            value: 'cost_per_total_next_day_open'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_add_payment_info'
                            ),
                            value: 'add_payment_info'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_add_payment_info'
                            ),
                            value: 'cost_per_add_payment_info'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_add_payment_info_rate'
                            ),
                            value: 'add_payment_info_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_add_payment_info'
                            ),
                            value: 'total_add_payment_info'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_total_add_payment_info'
                            ),
                            value: 'cost_total_add_payment_info'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_add_to_wishlist'
                            ),
                            value: 'add_to_wishlist'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_add_to_wishlist'
                            ),
                            value: 'cost_per_add_to_wishlist'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_add_to_wishlist_rate'
                            ),
                            value: 'add_to_wishlist_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_add_to_wishlist'
                            ),
                            value: 'total_add_to_wishlist'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_add_to_wishlist'
                            ),
                            value: 'cost_per_total_add_to_wishlist'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_add_to_wishlist'
                            ),
                            value: 'value_per_total_add_to_wishlist'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_add_to_wishlist_value'
                            ),
                            value: 'total_add_to_wishlist_value'
                        },
                        {
                            label: this.$t('page.campaign.column_launch_app'),
                            value: 'launch_app'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_launch_app'
                            ),
                            value: 'cost_per_launch_app'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_launch_app_rate'
                            ),
                            value: 'launch_app_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_launch_app'
                            ),
                            value: 'total_launch_app'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_launch_app'
                            ),
                            value: 'cost_per_total_launch_app'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_complete_tutorial'
                            ),
                            value: 'complete_tutorial'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_complete_tutorial'
                            ),
                            value: 'cost_per_complete_tutorial'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_complete_tutorial_rate'
                            ),
                            value: 'complete_tutorial_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_complete_tutorial'
                            ),
                            value: 'total_complete_tutorial'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_complete_tutorial'
                            ),
                            value: 'cost_per_total_complete_tutorial'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_complete_tutorial'
                            ),
                            value: 'value_per_total_complete_tutorial'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_complete_tutorial_value'
                            ),
                            value: 'total_complete_tutorial_value'
                        },
                        {
                            label: this.$t('page.campaign.column_create_group'),
                            value: 'create_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_create_group'
                            ),
                            value: 'cost_per_create_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_create_group_rate'
                            ),
                            value: 'create_group_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_create_group'
                            ),
                            value: 'total_create_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_create_group'
                            ),
                            value: 'cost_per_total_create_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_create_group'
                            ),
                            value: 'value_per_total_create_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_create_group_value'
                            ),
                            value: 'total_create_group_value'
                        },
                        {
                            label: this.$t('page.campaign.column_join_group'),
                            value: 'join_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_join_group'
                            ),
                            value: 'cost_per_join_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_join_group_rate'
                            ),
                            value: 'join_group_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_join_group'
                            ),
                            value: 'total_join_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_join_group'
                            ),
                            value: 'cost_per_total_join_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_join_group'
                            ),
                            value: 'value_per_total_join_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_join_group_value'
                            ),
                            value: 'total_join_group_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_create_gamerole'
                            ),
                            value: 'create_gamerole'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_create_gamerole'
                            ),
                            value: 'cost_per_create_gamerole'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_create_gamerole_rate'
                            ),
                            value: 'create_gamerole_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_create_gamerole'
                            ),
                            value: 'total_create_gamerole'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_create_gamerole'
                            ),
                            value: 'cost_per_total_create_gamerole'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_create_gamerole'
                            ),
                            value: 'value_per_total_create_gamerole'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_create_gamerole_value'
                            ),
                            value: 'total_create_gamerole_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_spend_credits'
                            ),
                            value: 'spend_credits'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_spend_credits'
                            ),
                            value: 'cost_per_spend_credits'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_spend_credits_rate'
                            ),
                            value: 'spend_credits_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_spend_credits'
                            ),
                            value: 'total_spend_credits'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_spend_credits'
                            ),
                            value: 'cost_per_total_spend_credits'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_spend_credits'
                            ),
                            value: 'value_per_total_spend_credits'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_spend_credits_value'
                            ),
                            value: 'total_spend_credits_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_achieve_level'
                            ),
                            value: 'achieve_level'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_achieve_level'
                            ),
                            value: 'cost_per_achieve_level'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_achieve_level_rate'
                            ),
                            value: 'achieve_level_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_achieve_level'
                            ),
                            value: 'total_achieve_level'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_achieve_level'
                            ),
                            value: 'cost_per_total_achieve_level'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_achieve_level'
                            ),
                            value: 'value_per_total_achieve_level'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_achieve_level_value'
                            ),
                            value: 'total_achieve_level_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_unlock_achievement'
                            ),
                            value: 'unlock_achievement'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_unlock_achievement'
                            ),
                            value: 'cost_per_unlock_achievement'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_unlock_achievement_rate'
                            ),
                            value: 'unlock_achievement_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_unlock_achievement'
                            ),
                            value: 'total_unlock_achievement'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_unlock_achievement'
                            ),
                            value: 'cost_per_total_unlock_achievement'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_unlock_achievement'
                            ),
                            value: 'value_per_total_unlock_achievement'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_unlock_achievement_value'
                            ),
                            value: 'total_unlock_achievement_value'
                        },
                        {
                            label: this.$t('page.campaign.column_sales_lead'),
                            value: 'sales_lead'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_sales_lead'
                            ),
                            value: 'cost_per_sales_lead'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_sales_lead_rate'
                            ),
                            value: 'sales_lead_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_sales_lead'
                            ),
                            value: 'total_sales_lead'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_sales_lead'
                            ),
                            value: 'cost_per_total_sales_lead'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_sales_lead'
                            ),
                            value: 'value_per_total_sales_lead'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_sales_lead_value'
                            ),
                            value: 'total_sales_lead_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_in_app_ad_click'
                            ),
                            value: 'in_app_ad_click'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_in_app_ad_click'
                            ),
                            value: 'cost_per_in_app_ad_click'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_in_app_ad_click_rate'
                            ),
                            value: 'in_app_ad_click_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_in_app_ad_click'
                            ),
                            value: 'total_in_app_ad_click'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_in_app_ad_click'
                            ),
                            value: 'cost_per_total_in_app_ad_click'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_in_app_ad_click'
                            ),
                            value: 'value_per_total_in_app_ad_click'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_in_app_ad_click_value'
                            ),
                            value: 'total_in_app_ad_click_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_in_app_ad_impr'
                            ),
                            value: 'in_app_ad_impr'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_in_app_ad_impr'
                            ),
                            value: 'cost_per_in_app_ad_impr'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_in_app_ad_impr_rate'
                            ),
                            value: 'in_app_ad_impr_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_in_app_ad_impr'
                            ),
                            value: 'total_in_app_ad_impr'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_in_app_ad_impr'
                            ),
                            value: 'cost_per_total_in_app_ad_impr'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_in_app_ad_impr'
                            ),
                            value: 'value_per_total_in_app_ad_impr'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_in_app_ad_impr_value'
                            ),
                            value: 'total_in_app_ad_impr_value'
                        },
                        {
                            label: this.$t('page.campaign.column_loan_apply'),
                            value: 'loan_apply'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_loan_apply'
                            ),
                            value: 'cost_per_loan_apply'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_loan_apply_rate'
                            ),
                            value: 'loan_apply_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_loan_apply'
                            ),
                            value: 'total_loan_apply'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_loan_apply'
                            ),
                            value: 'cost_per_total_loan_apply'
                        },
                        {
                            label: this.$t('page.campaign.column_loan_credit'),
                            value: 'loan_credit'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_loan_credit'
                            ),
                            value: 'cost_per_loan_credit'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_loan_credit_rate'
                            ),
                            value: 'loan_credit_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_loan_credit'
                            ),
                            value: 'total_loan_credit'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_loan_credit'
                            ),
                            value: 'cost_per_total_loan_credit'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_loan_disbursement'
                            ),
                            value: 'loan_disbursement'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_loan_disbursement'
                            ),
                            value: 'cost_per_loan_disbursement'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_loan_disbursement_rate'
                            ),
                            value: 'loan_disbursement_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_loan_disbursement'
                            ),
                            value: 'total_loan_disbursement'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_loan_disbursement'
                            ),
                            value: 'cost_per_total_loan_disbursement'
                        },
                        {
                            label: this.$t('page.campaign.column_login'),
                            value: 'login'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_login'
                            ),
                            value: 'cost_per_login'
                        },
                        {
                            label: this.$t('page.campaign.column_login_rate'),
                            value: 'login_rate'
                        },
                        {
                            label: this.$t('page.campaign.column_total_login'),
                            value: 'total_login'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_login'
                            ),
                            value: 'cost_per_total_login'
                        },
                        {
                            label: this.$t('page.campaign.column_ratings'),
                            value: 'ratings'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_ratings'
                            ),
                            value: 'cost_per_ratings'
                        },
                        {
                            label: this.$t('page.campaign.column_ratings_rate'),
                            value: 'ratings_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_ratings'
                            ),
                            value: 'total_ratings'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_ratings'
                            ),
                            value: 'cost_per_total_ratings'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_ratings'
                            ),
                            value: 'value_per_total_ratings'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_ratings_value'
                            ),
                            value: 'total_ratings_value'
                        },
                        {
                            label: this.$t('page.campaign.column_search'),
                            value: 'search'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_search'
                            ),
                            value: 'cost_per_search'
                        },
                        {
                            label: this.$t('page.campaign.column_search_rate'),
                            value: 'search_rate'
                        },
                        {
                            label: this.$t('page.campaign.column_total_search'),
                            value: 'total_search'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_search'
                            ),
                            value: 'cost_per_total_search'
                        },
                        {
                            label: this.$t('page.campaign.column_start_trial'),
                            value: 'start_trial'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_start_trial'
                            ),
                            value: 'cost_per_start_trial'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_start_trial_rate'
                            ),
                            value: 'start_trial_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_start_trial'
                            ),
                            value: 'total_start_trial'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_start_trial'
                            ),
                            value: 'cost_per_total_start_trial'
                        },
                        {
                            label: this.$t('page.campaign.column_subscribe'),
                            value: 'subscribe'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_subscribe'
                            ),
                            value: 'cost_per_subscribe'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_subscribe_rate'
                            ),
                            value: 'subscribe_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_subscribe'
                            ),
                            value: 'total_subscribe'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_subscribe'
                            ),
                            value: 'cost_per_total_subscribe'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_subscribe'
                            ),
                            value: 'value_per_total_subscribe'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_subscribe_value'
                            ),
                            value: 'total_subscribe_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_unique_custom_app_events'
                            ),
                            value: 'unique_custom_app_events'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_unique_custom_app_event'
                            ),
                            value: 'cost_per_unique_custom_app_event'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_custom_app_event_rate'
                            ),
                            value: 'custom_app_event_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_custom_app_events'
                            ),
                            value: 'custom_app_events'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_custom_app_event'
                            ),
                            value: 'cost_per_custom_app_event'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_custom_app_event'
                            ),
                            value: 'value_per_custom_app_event'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_custom_app_events_value'
                            ),
                            value: 'custom_app_events_value'
                        }
                    ]
                },
                // {
                //     label: this.$t('page.campaign.custom_column_on_site_event'),
                //     options: [
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_onsite_shopping_roas'
                //             ),
                //             value: 'onsite_shopping_roas'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_onsite_shopping'
                //             ),
                //             value: 'onsite_shopping'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_onsite_shopping'
                //             ),
                //             value: 'cost_per_onsite_shopping'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_onsite_shopping_rate'
                //             ),
                //             value: 'onsite_shopping_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_onsite_shopping'
                //             ),
                //             value: 'value_per_onsite_shopping'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_onsite_shopping_value'
                //             ),
                //             value: 'total_onsite_shopping_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_onsite_initiate_checkout_count'
                //             ),
                //             value: 'onsite_initiate_checkout_count'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_onsite_initiate_checkout_count'
                //             ),
                //             value: 'cost_per_onsite_initiate_checkout_count'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_onsite_initiate_checkout_count_rate'
                //             ),
                //             value: 'onsite_initiate_checkout_count_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_onsite_initiate_checkout_count'
                //             ),
                //             value: 'value_per_onsite_initiate_checkout_count'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_onsite_initiate_checkout_count_value'
                //             ),
                //             value: 'total_onsite_initiate_checkout_count_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_onsite_on_web_detail'
                //             ),
                //             value: 'onsite_on_web_detail'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_onsite_on_web_detail'
                //             ),
                //             value: 'cost_per_onsite_on_web_detail'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_onsite_on_web_detail_rate'
                //             ),
                //             value: 'onsite_on_web_detail_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_onsite_on_web_detail'
                //             ),
                //             value: 'value_per_onsite_on_web_detail'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_onsite_on_web_detail_value'
                //             ),
                //             value: 'total_onsite_on_web_detail_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_onsite_add_to_wishlist'
                //             ),
                //             value: 'onsite_add_to_wishlist'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_onsite_add_to_wishlist'
                //             ),
                //             value: 'cost_per_onsite_add_to_wishlist'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_onsite_add_to_wishlist_rate'
                //             ),
                //             value: 'onsite_add_to_wishlist_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_onsite_add_to_wishlist'
                //             ),
                //             value: 'value_per_onsite_add_to_wishlist'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_onsite_add_to_wishlist_value'
                //             ),
                //             value: 'total_onsite_add_to_wishlist_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_onsite_add_billing'
                //             ),
                //             value: 'onsite_add_billing'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_onsite_add_billing'
                //             ),
                //             value: 'cost_per_onsite_add_billing'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_onsite_add_billing_rate'
                //             ),
                //             value: 'onsite_add_billing_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_onsite_add_billing'
                //             ),
                //             value: 'value_per_onsite_add_billing'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_onsite_add_billing_value'
                //             ),
                //             value: 'total_onsite_add_billing_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_onsite_on_web_cart'
                //             ),
                //             value: 'onsite_on_web_cart'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_onsite_on_web_cart'
                //             ),
                //             value: 'cost_per_onsite_on_web_cart'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_onsite_on_web_cart_rate'
                //             ),
                //             value: 'onsite_on_web_cart_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_onsite_on_web_cart'
                //             ),
                //             value: 'value_per_onsite_on_web_cart'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_onsite_on_web_cart_value'
                //             ),
                //             value: 'total_onsite_on_web_cart_value'
                //         },
                //         {
                //             label: this.$t('page.campaign.column_onsite_form'),
                //             value: 'onsite_form'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_onsite_form'
                //             ),
                //             value: 'cost_per_onsite_form'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_onsite_form_rate'
                //             ),
                //             value: 'onsite_form_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_onsite_form'
                //             ),
                //             value: 'value_per_onsite_form'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_onsite_form_value'
                //             ),
                //             value: 'total_onsite_form_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_onsite_download_start'
                //             ),
                //             value: 'onsite_download_start'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_onsite_download_start'
                //             ),
                //             value: 'cost_per_onsite_download_start'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_onsite_download_start_rate'
                //             ),
                //             value: 'onsite_download_start_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_ix_page_view_count'
                //             ),
                //             value: 'ix_page_view_count'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_ix_page_view_count'
                //             ),
                //             value: 'cost_per_ix_page_view_count'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_ix_page_view_count_rate'
                //             ),
                //             value: 'ix_page_view_count_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_ix_button_click_count'
                //             ),
                //             value: 'ix_button_click_count'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_ix_button_click_count'
                //             ),
                //             value: 'cost_per_ix_button_click_count'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_ix_button_click_count_rate'
                //             ),
                //             value: 'ix_button_click_count_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_ix_product_click_count'
                //             ),
                //             value: 'ix_product_click_count'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_ix_product_click_count'
                //             ),
                //             value: 'cost_per_ix_product_click_count'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_ix_product_click_count_rate'
                //             ),
                //             value: 'ix_product_click_count_rate'
                //         }
                //     ]
                // },
                // {
                //     label: this.$t('page.campaign.custom_column_page_event'),
                //     options: [
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_complete_payment_roas'
                //             ),
                //             value: 'complete_payment_roas'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_complete_payment'
                //             ),
                //             value: 'complete_payment'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_complete_payment'
                //             ),
                //             value: 'cost_per_complete_payment'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_complete_payment_rate'
                //             ),
                //             value: 'complete_payment_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_complete_payment'
                //             ),
                //             value: 'value_per_complete_payment'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_complete_payment_rate'
                //             ),
                //             value: 'total_complete_payment_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_landing_page_view'
                //             ),
                //             value: 'total_landing_page_view'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_landing_page_view'
                //             ),
                //             value: 'cost_per_landing_page_view'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_landing_page_view_rate'
                //             ),
                //             value: 'landing_page_view_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_pageview'
                //             ),
                //             value: 'total_pageview'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_pageview'
                //             ),
                //             value: 'cost_per_pageview'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_pageview_rate'
                //             ),
                //             value: 'pageview_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_avg_value_per_pageview'
                //             ),
                //             value: 'avg_value_per_pageview'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_value_per_pageview'
                //             ),
                //             value: 'total_value_per_pageview'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_page_browse_view'
                //             ),
                //             value: 'page_browse_view'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_page_browse_view'
                //             ),
                //             value: 'cost_per_page_browse_view'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_page_browse_view_rate'
                //             ),
                //             value: 'page_browse_view_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_page_browse_view_value'
                //             ),
                //             value: 'total_page_browse_view_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_page_browse_view'
                //             ),
                //             value: 'value_per_page_browse_view'
                //         },
                //         {
                //             label: this.$t('page.campaign.column_button_click'),
                //             value: 'button_click'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_button_click'
                //             ),
                //             value: 'cost_per_button_click'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_button_click_rate'
                //             ),
                //             value: 'button_click_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_button_click'
                //             ),
                //             value: 'value_per_button_click'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_button_click_value'
                //             ),
                //             value: 'total_button_click_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_online_consult'
                //             ),
                //             value: 'online_consult'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_online_consult'
                //             ),
                //             value: 'cost_per_online_consult'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_online_consult_rate'
                //             ),
                //             value: 'online_consult_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_online_consult'
                //             ),
                //             value: 'value_per_online_consult'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_online_consult_value'
                //             ),
                //             value: 'total_online_consult_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_user_registration'
                //             ),
                //             value: 'user_registration'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_user_registration'
                //             ),
                //             value: 'cost_per_user_registration'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_user_registration_rate'
                //             ),
                //             value: 'user_registration_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_user_registration'
                //             ),
                //             value: 'value_per_user_registration'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_user_registration_value'
                //             ),
                //             value: 'total_user_registration_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_product_details_page_browse'
                //             ),
                //             value: 'product_details_page_browse'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_product_details_page_browse'
                //             ),
                //             value: 'cost_per_product_details_page_browse'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_product_details_page_browse_rate'
                //             ),
                //             value: 'product_details_page_browse_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_product_details_page_browse'
                //             ),
                //             value: 'value_per_product_details_page_browse'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_product_details_page_browse_value'
                //             ),
                //             value: 'total_product_details_page_browse_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_web_event_add_to_cart'
                //             ),
                //             value: 'web_event_add_to_cart'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_web_event_add_to_cart'
                //             ),
                //             value: 'cost_per_web_event_add_to_cart'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_web_event_add_to_cart_rate'
                //             ),
                //             value: 'web_event_add_to_cart_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_web_event_add_to_cart'
                //             ),
                //             value: 'value_per_web_event_add_to_cart'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_web_event_add_to_cart_value'
                //             ),
                //             value: 'total_web_event_add_to_cart_value'
                //         },
                //         {
                //             label: this.$t('page.campaign.column_on_web_order'),
                //             value: 'on_web_order'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_on_web_order'
                //             ),
                //             value: 'cost_per_on_web_order'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_on_web_order_rate'
                //             ),
                //             value: 'on_web_order_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_on_web_order'
                //             ),
                //             value: 'value_per_on_web_order'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_on_web_order_value'
                //             ),
                //             value: 'total_on_web_order_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_initiate_checkout'
                //             ),
                //             value: 'initiate_checkout'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_initiate_checkout'
                //             ),
                //             value: 'cost_per_initiate_checkout'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_initiate_checkout_rate'
                //             ),
                //             value: 'initiate_checkout_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_initiate_checkout'
                //             ),
                //             value: 'value_per_initiate_checkout'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_initiate_checkout_value'
                //             ),
                //             value: 'total_initiate_checkout_value'
                //         },
                //         {
                //             label: this.$t('page.campaign.column_add_billing'),
                //             value: 'add_billing'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_add_billing'
                //             ),
                //             value: 'cost_per_add_billing'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_add_billing_rate'
                //             ),
                //             value: 'add_billing_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_add_billing'
                //             ),
                //             value: 'value_per_add_billing'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_add_billing_value'
                //             ),
                //             value: 'total_add_billing_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_page_event_search'
                //             ),
                //             value: 'page_event_search'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_page_event_search'
                //             ),
                //             value: 'cost_per_page_event_search'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_page_event_search_rate'
                //             ),
                //             value: 'page_event_search_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_page_event_search'
                //             ),
                //             value: 'value_per_page_event_search'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_page_event_search_value'
                //             ),
                //             value: 'total_page_event_search_value'
                //         },
                //         {
                //             label: this.$t('page.campaign.column_form'),
                //             value: 'form'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_form'
                //             ),
                //             value: 'cost_per_form'
                //         },
                //         {
                //             label: this.$t('page.campaign.column_form_rate'),
                //             value: 'form_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_form'
                //             ),
                //             value: 'value_per_form'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_form_value'
                //             ),
                //             value: 'total_form_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_download_start'
                //             ),
                //             value: 'download_start'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_download_start'
                //             ),
                //             value: 'cost_per_download_start'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_download_start_rate'
                //             ),
                //             value: 'download_start_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_download_start'
                //             ),
                //             value: 'value_per_download_start'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_download_start_value'
                //             ),
                //             value: 'total_download_start_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_on_web_add_to_wishlist'
                //             ),
                //             value: 'on_web_add_to_wishlist'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_on_web_add_to_wishlist'
                //             ),
                //             value: 'cost_per_on_web_add_to_wishlist'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_on_web_add_to_wishlist_per_click'
                //             ),
                //             value: 'on_web_add_to_wishlist_per_click'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_on_web_add_to_wishlist'
                //             ),
                //             value: 'value_per_on_web_add_to_wishlist'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_on_web_add_to_wishlist_value'
                //             ),
                //             value: 'total_on_web_add_to_wishlist_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_on_web_subscribe'
                //             ),
                //             value: 'on_web_subscribe'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_on_web_subscribe'
                //             ),
                //             value: 'cost_per_on_web_subscribe'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_on_web_subscribe_per_click'
                //             ),
                //             value: 'on_web_subscribe_per_click'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_on_web_subscribe'
                //             ),
                //             value: 'value_per_on_web_subscribe'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_total_on_web_subscribe_value'
                //             ),
                //             value: 'total_on_web_subscribe_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_custom_page_events'
                //             ),
                //             value: 'custom_page_events'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_custom_page_event'
                //             ),
                //             value: 'cost_per_custom_page_event'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_custom_page_event_rate'
                //             ),
                //             value: 'custom_page_event_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_custom_page_event'
                //             ),
                //             value: 'value_per_custom_page_event'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_custom_page_events_value'
                //             ),
                //             value: 'custom_page_events_value'
                //         }
                //     ]
                // },
                {
                    label: this.$t('page.campaign.custom_column_attribution'),
                    options: [
                        {
                            label: this.$t(
                                'page.campaign.column_vta_app_install'
                            ),
                            value: 'vta_app_install'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_vta_app_install'
                            ),
                            value: 'cost_per_vta_app_install'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_vta_conversion'
                            ),
                            value: 'vta_conversion'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_vta_conversion'
                            ),
                            value: 'cost_per_vta_conversion'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_vta_registration'
                            ),
                            value: 'vta_registration'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_vta_registration'
                            ),
                            value: 'cost_per_vta_registration'
                        },
                        {
                            label: this.$t('page.campaign.column_vta_purchase'),
                            value: 'vta_purchase'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_vta_purchase'
                            ),
                            value: 'cost_per_vta_purchase'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cta_app_install'
                            ),
                            value: 'cta_app_install'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_cta_app_install'
                            ),
                            value: 'cost_per_cta_app_install'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cta_conversion'
                            ),
                            value: 'cta_conversion'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_cta_conversion'
                            ),
                            value: 'cost_per_cta_conversion'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cta_registration'
                            ),
                            value: 'cta_registration'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_cta_registration'
                            ),
                            value: 'cost_per_cta_registration'
                        },
                        {
                            label: this.$t('page.campaign.column_cta_purchase'),
                            value: 'cta_purchase'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_cta_purchase'
                            ),
                            value: 'cost_per_cta_purchase'
                        }
                    ]
                }
            ]

            if (this.dimension === 'CAMPAIGN') {
                this.subject_type_options = [
                    {
                        label: this.$t('common.setting'),
                        options: [
                            {
                                value: 'OBJECTIVE',
                                label: this.$t('common.objective')
                            },
                            {
                                value: 'NAME',
                                label: this.$t('common.name')
                            }
                        ]
                    },
                    ...temp
                ]
                return
            }
            if (this.dimension === 'ADGROUP') {
                this.subject_type_options = [
                    {
                        label: this.$t('common.setting'),
                        options: [
                            {
                                value: 'BID_STRATEGY',
                                label: this.$t('page.campaign.bid_strategy')
                            },
                            {
                                value: 'OPTIMIZATION_GOAL',
                                label: this.$t(
                                    'page.campaign.optimization_goal'
                                )
                            },
                            {
                                value: 'NAME',
                                label: this.$t('common.name')
                            }
                        ]
                    },
                    ...temp
                ]
                return
            }
            if (this.dimension === 'AD') {
                this.subject_type_options = [
                    {
                        label: this.$t('common.setting'),
                        options: [
                            {
                                value: 'NAME',
                                label: this.$t('common.name')
                            }
                        ]
                    },
                    ...temp
                ]
                return
            }
        },

        showSpanSubjectType() {
            if (
                this.subject_type_show_9_spans.some(
                    (i) => i === this.item_condition.subject_type
                )
            ) {
                return 9
            }
            if (
                this.subject_type_show_6_spans.some(
                    (i) => i === this.item_condition.subject_type
                )
            ) {
                return 6
            }

            return 3
        },

        disableMatchType() {
            if (
                ['OBJECTIVE'].some(
                    (i) => i === this.item_condition.subject_type
                )
            ) {
                return true
            }

            return false
        },

        showCalculationType() {
            if (
                [
                    ...this.subject_type_show_6_spans,
                    ...this.subject_type_show_9_spans
                ].every((i) => i !== this.item_condition.subject_type)
            ) {
                return true
            }

            return false
        },

        showRangeType() {
            if (
                this.subject_type_show_9_spans.every(
                    (i) => i !== this.item_condition.subject_type
                )
            ) {
                return true
            }

            return false
        },

        showSelectObjectiveTypes() {
            if (
                ['OBJECTIVE'].some(
                    (i) => i === this.item_condition.subject_type
                )
            ) {
                return true
            }

            return false
        },

        showSelectBidStrategyTypes() {
            if (
                ['BID_STRATEGY'].some(
                    (i) => i === this.item_condition.subject_type
                )
            ) {
                return true
            }

            return false
        },

        showSelectOptimizationGoalTypes() {
            if (
                ['OPTIMIZATION_GOAL'].some(
                    (i) => i === this.item_condition.subject_type
                )
            ) {
                return true
            }

            return false
        },

        showInputRange() {
            if (this.item_condition.match_type === 'BETWEEN') {
                return true
            }

            return false
        },

        showInputName() {
            if (this.item_condition.subject_type === 'NAME') {
                return true
            }

            return false
        },

        renderAppendInputValue() {
            if (
                [
                    'DAILY_BUDGET_SPENDING_RATE',
                    'LIFETIME_BUDGET_SPENDING_RATE'
                ].some((item) => item === this.item_condition.subject_type)
            ) {
                return '%'
            }

            if (this.item_condition.subject_type === 'DAYS_SINCE_CREATION') {
                return this.$t('common.day')
            }

            if (
                ['IMPRESSION', 'CONVERSION', 'CLICK'].some(
                    (item) => item === this.item_condition.subject_type
                )
            ) {
                return this.$t('common.turn')
            }

            return this.currency
        }
    }
}
</script>
