<template>
    <div v-if="item_action">
        <el-row :gutter="12">
            <el-col :span="2">
                <div
                    v-if="index === 0"
                    class="bg-[#3955f61f] util-flex-center rounded-r-lg h-8"
                >
                    <p class="text-sm text-[#3955f6] font-semibold">
                        {{ $t('common.then') }}
                    </p>
                </div>

                <div
                    v-else
                    class="bg-[#ebebeb] util-flex-center rounded-r-lg h-8"
                >
                    <p class="text-sm">{{ $t('common.and') }}</p>
                </div>
            </el-col>
            <el-col :span="9">
                <el-select
                    v-model="item_action.subject_type"
                    class="w-full"
                    size="small"
                    placeholder="Select"
                >
                    <el-option
                        v-for="item in action_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        :disabled="
                            selected_actions_in_line.some(
                                (i) => i.subject_type === item.value
                            )
                        "
                    >
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="2">
                <i
                    v-if="selected_actions.length > 1"
                    class="el-icon-delete mt-2 cursor-pointer"
                    @click="handle_remove(index)"
                ></i>
            </el-col>
        </el-row>
        <el-row
            v-if="
                ['DAILY_BUDGET', 'LIFETIME_BUDGET', 'BID'].some(
                    (item) => item === item_action.subject_type
                )
            "
            :gutter="12"
            class="mt-2"
        >
            <el-col :span="2"> <div class="invisible">temp</div></el-col>

            <el-col :span="9">
                <p class="text-desc-text font-semibold text-xs">
                    {{ $t('common.daily_budget') }}
                </p>

                <el-input
                    v-model="item_action.value.value"
                    size="small"
                    type="number"
                    :min="1"
                >
                    <el-select
                        slot="prepend"
                        v-model="item_action.action_type"
                        class="w-[160px]"
                    >
                        <el-option
                            v-for="item in action_type_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>

                    <el-select
                        v-if="item_action.action_type !== 'ADJUST_TO'"
                        slot="append"
                        v-model="item_action.value_type"
                        class="w-[100px]"
                    >
                        <el-option
                            v-for="item in value_type_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-input>
            </el-col>

            <el-col
                v-if="
                    ['DECREASE', 'INCREASE'].some(
                        (item) => item === item_action.action_type
                    )
                "
                :span="5"
            >
                <p class="text-desc-text font-semibold text-xs">
                    {{ showValueLimit() }}
                </p>

                <el-input-number
                    v-model="item_action.value.limit"
                    controls-position="right"
                    :min="50"
                    :max="10000000"
                    size="small"
                    class="w-full"
                ></el-input-number>
            </el-col>
        </el-row>
        <el-row
            v-if="
                ['DAILY_BUDGET', 'LIFETIME_BUDGET', 'BID'].some(
                    (item) => item === item_action.subject_type
                )
            "
            :gutter="12"
            class="mt-2"
        >
            <el-col :span="2"> <div class="invisible">temp</div></el-col>

            <el-col :span="9">
                <p class="text-desc-text font-semibold text-xs">
                    {{ $t('page.campaign.action_frequency_cap') }}
                </p>

                <el-select
                    v-model="item_action.frequency_info.type"
                    size="small"
                    class="w-full"
                    :disabled="item_action.action_type === 'ADJUST_TO'"
                >
                    <el-option
                        v-for="item in frequency_info_type_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
        <el-row
            v-if="item_action.subject_type === 'SET_END_TIME'"
            :gutter="12"
            class="mt-2"
        >
            <el-col :span="2"> <div class="invisible">temp</div></el-col>

            <el-col :span="6">
                <p class="text-desc-text font-semibold text-xs">
                    {{ $t('common.end_datetime') }}
                </p>

                <el-date-picker
                    v-model="item_action.time_end"
                    type="datetime"
                    size="small"
                    class="w-full"
                    :format="$t('common.format_value_date_picker_with_time')"
                    :picker-options="picker_options_for_now"
                ></el-date-picker>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    props: [
        'selected_actions',
        'index',
        'handle_remove',
        'dimension',
        'currency'
    ],

    data() {
        return {
            item_action: null,
            action_options: [],
            picker_options_for_now: {
                disabledDate: (date) => this.disabledDateForNow(date)
            },
            value_type_options: [
                {
                    value: 'EXACT',
                    label: this.currency
                },
                {
                    value: 'PERCENT',
                    label: '%'
                }
            ],
            action_type_options: [
                {
                    value: 'INCREASE',
                    label: this.$t('common.increase')
                },
                {
                    value: 'DECREASE',
                    label: this.$t('common.decrease')
                },
                {
                    value: 'ADJUST_TO',
                    label: this.$t('common.adjust_to')
                }
            ],
            frequency_info_type_options: [
                {
                    value: 'ONLY_ONCE',
                    label: this.$t('page.campaign.only_once')
                },
                {
                    value: 'ONCE_IN_24_H',
                    label: this.$t('page.campaign.once_in_24_h')
                },
                {
                    value: 'ONCE_IN_48_H',
                    label: this.$t('page.campaign.once_in_48_h')
                },
                {
                    value: 'ONCE_IN_1_W',
                    label: this.$t('page.campaign.once_in_1_w')
                }
            ]
        }
    },

    computed: {
        selected_actions_in_line: {
            get() {
                return this.selected_actions
            },
            set(value) {
                this.$emit('update:selected_actions', value)
            }
        }
    },

    watch: {
        item_action() {
            this.handleChangeDimension()
        },

        'item_action.subject_type'() {
            if (this.item_action.subject_type === 'SET_END_TIME') {
                const now = moment()
                this.item_action.time_end = now
                    .add(10, 'minutes')
                    .format(this.p__format_schedule_tiktok_time)
            }
        },

        'item_action.action_type'() {
            if (!this.item_action) {
                return
            }

            this.item_action.value.limit = 0
            this.item_action.value.value = 0
            this.item_action.frequency_info.type = 'ONLY_ONCE'

            if (
                ['INCREASE', 'DECREASE'].some(
                    (item) => item === this.item_action.action_type
                )
            ) {
                this.item_action.value.use_limit = true
            } else {
                this.item_action.value_type = 'EXACT'
                this.item_action.value.use_limit = false
            }
        },

        selected_actions_in_line() {
            this.item_action = this.selected_actions_in_line[this.index]
        }
    },

    mounted() {
        this.handleChangeDimension()

        this.item_action = this.selected_actions_in_line[this.index]
    },

    methods: {
        handleChangeDimension() {
            if (!this.item_action) {
                return
            }

            // this.item_action.subject_type = 'TURN_ON'

            const temp = [
                {
                    value: 'TURN_ON',
                    label: this.$t('common.turn_on')
                },
                {
                    value: 'TURN_OFF',
                    label: this.$t('common.turn_off')
                },
                {
                    value: 'MESSAGE',
                    label: this.$t('page.campaign.notify_me')
                }
            ]

            if (this.dimension === 'CAMPAIGN') {
                this.action_options = [
                    ...temp,
                    {
                        value: 'DAILY_BUDGET',
                        label: this.$t('page.campaign.adjust_daily_budget')
                    },
                    {
                        value: 'LIFETIME_BUDGET',
                        label: this.$t('page.campaign.adjust_lifetime_budget')
                    }
                ]

                return
            }
            if (this.dimension === 'ADGROUP') {
                this.action_options = [
                    ...temp,
                    {
                        value: 'SET_END_TIME',
                        label: this.$t('page.campaign.set_end_time')
                    },
                    {
                        value: 'PACING_MODE_SMOOTH',
                        label: this.$t('page.campaign.pacing_smooth_to_fast')
                    },
                    {
                        value: 'PACING_MODE_FAST',
                        label: this.$t('page.campaign.pacing_fast_to_smooth')
                    },
                    {
                        value: 'DAILY_BUDGET',
                        label: this.$t('page.campaign.adjust_daily_budget')
                    },
                    {
                        value: 'LIFETIME_BUDGET',
                        label: this.$t('page.campaign.adjust_lifetime_budget')
                    },
                    {
                        value: 'BID',
                        label: this.$t('page.campaign.adjust_bid')
                    }
                ]

                return
            }
            if (this.dimension === 'AD') {
                this.action_options = [...temp]

                return
            }
        },

        disabledDateForNow(date) {
            return moment(date) <= moment().add(1, 'hours')
        },

        showValueLimit() {
            if (!this.item_action) {
                return ''
            }
            if (this.item_action.action_type === 'INCREASE') {
                if (this.item_action.subject_type === 'DAILY_BUDGET') {
                    return this.$t('page.campaign.maximum_daily_budget_cap')
                } else if (
                    this.item_action.subject_type === 'LIFETIME_BUDGET'
                ) {
                    return this.$t('page.campaign.maximum_lifetime_budget_cap')
                } else if (this.item_action.subject_type === 'BID') {
                    return this.$t('page.campaign.maximum_bid_cap')
                }
            } else if (this.item_action.action_type === 'DECREASE') {
                if (this.item_action.subject_type === 'DAILY_BUDGET') {
                    return this.$t('page.campaign.minimum_daily_budget')
                } else if (
                    this.item_action.subject_type === 'LIFETIME_BUDGET'
                ) {
                    return this.$t('page.campaign.minimum_lifetime_budget')
                } else if (this.item_action.subject_type === 'BID') {
                    return this.$t('page.campaign.minimum_bid')
                }
            }
            return ''
        }
    }
}
</script>
