<template>
    <div class="bg-bg p-4">
        <p class="text-xs font-semibold">
            {{ $t('page.campaign.notification_schedule') }}
        </p>
        <el-radio-group
            v-model="notification_inline.email_setting.notification_period"
            class="block mt-1"
        >
            <el-radio label="EVERY_TIME">
                {{ $t('page.campaign.notify_me_every_time') }}
            </el-radio>
        </el-radio-group>
        <el-radio-group
            v-model="notification_inline.email_setting.notification_period"
            class="block mt-3"
        >
            <el-radio label="TIME_SCHEDULE">
                {{ $t('page.campaign.send_notification_together') }}
            </el-radio>
        </el-radio-group>
        <div
            v-if="
                notification_inline.email_setting.notification_period ===
                'TIME_SCHEDULE'
            "
            class="mt-2"
        >
            <el-select
                v-model="notification_inline.email_setting.email_exec_time"
                class="w-[240px]"
                size="small"
                multiple
                :multiple-limit="3"
            >
                <el-option
                    v-for="item in specified_time_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
        </div>

        <div class="flex items-center mt-6 space-x-2">
            <el-switch
                v-model="
                    notification_inline.email_setting.no_result_notification
                "
            >
            </el-switch>
            <p class="text-sm">
                {{ $t('page.campaign.also_notify_me') }}
            </p>
        </div>
        <p class="text-desc-text text-xs">
            {{ $t('page.campaign.get_notification_every_time') }}
        </p>
    </div>
</template>

<script>
export default {
    props: ['notification'],

    data() {
        return {
            specified_time_options: []
        }
    },

    computed: {
        notification_inline: {
            get: function () {
                return this.notification
            },
            set: function (val) {
                this.$emit('update:notification', val)
            }
        }
    },

    watch: {
        'notification_inline.email_setting.notification_period'() {
            if (
                this.notification_inline.email_setting.notification_period ===
                    'TIME_SCHEDULE' &&
                this.specified_time_options.length
            ) {
                this.notification_inline.email_setting.email_exec_time = [
                    this.specified_time_options[0].value
                ]
            } else {
                this.notification_inline.email_setting.email_exec_time = []
            }
        }
    },

    mounted() {
        this.renderSpecifiedTimeOptions()
    },

    methods: {
        renderSpecifiedTimeOptions() {
            const temp = []

            for (let index = 0; index < 24; index++) {
                let hour = `0${index}`
                if (index >= 10) {
                    hour = index
                }

                let value = `${hour}:15`
                temp.push({
                    label: value,
                    value
                })

                value = `${hour}:45`
                temp.push({
                    label: value,
                    value
                })
            }

            this.specified_time_options = temp
        }
    }
}
</script>
