<template>
    <div class="bg-bg py-4">
        <el-row :gutter="12">
            <el-col :span="2"> <div class="invisible">temp</div></el-col>
            <el-col :span="3">
                <p class="text-desc-text font-semibold text-xs">
                    {{ $t('common.condition') }}
                </p>
            </el-col>
            <el-col :span="3">
                <p class="text-desc-text font-semibold text-xs">
                    {{ $t('page.campaign.calculation_method') }}
                </p>
            </el-col>
            <el-col :span="3">
                <p class="text-desc-text font-semibold text-xs">
                    {{ $t('page.campaign.time_range') }}
                </p>
            </el-col>
        </el-row>

        <div class="flex flex-col space-y-3">
            <Condition
                v-for="(item_condition, index) of selected_conditions_in_line"
                :key="index"
                :index="index"
                :handle_remove="handleRemoveConditions"
                :dimension="dimension"
                :currency="currency"
                :list_selected_subject_type="list_selected_subject_type"
                :selected_conditions.sync="selected_conditions_in_line"
            >
            </Condition>
        </div>

        <el-row :gutter="12" class="mt-4">
            <el-col :span="2"> <div class="invisible">temp</div></el-col>
            <el-col :span="3">
                <el-button
                    icon="el-icon-plus"
                    type="text"
                    :disabled="
                        selected_conditions_in_line.length === max_conditions
                    "
                    @click="handleAddCondition()"
                >
                    {{ $t('page.campaign.add_condition') }} ({{
                        selected_conditions_in_line.length
                    }}
                    / {{ max_conditions }})
                </el-button>
            </el-col>
        </el-row>

        <el-row :gutter="12" class="mt-10">
            <el-col :span="2"> <div class="invisible">temp</div></el-col>
            <el-col :span="3">
                <p class="text-desc-text font-semibold text-xs">
                    {{ $t('common.action') }}
                </p>
            </el-col>
        </el-row>

        <div class="flex flex-col space-y-3">
            <Action
                v-for="(item_actions, index) of selected_actions_in_line"
                :key="index"
                :index="index"
                :handle_remove="handleRemoveActions"
                :dimension="dimension"
                :currency="currency"
                :selected_actions.sync="selected_actions_in_line"
            >
            </Action>
        </div>

        <el-row :gutter="12" class="mt-4">
            <el-col :span="2"> <div class="invisible">temp</div></el-col>
            <el-col :span="3">
                <el-button
                    icon="el-icon-plus"
                    type="text"
                    :disabled="selected_actions_in_line.length === max_actions"
                    @click="handleAddAction()"
                >
                    {{ $t('page.campaign.add_action') }} ({{
                        selected_actions_in_line.length
                    }}
                    / {{ max_actions }})
                </el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Condition from './condition'
import Action from './action'

export default {
    components: {
        Condition,
        Action
    },

    props: ['selected_conditions', 'selected_actions', 'dimension', 'currency'],

    data() {
        return {
            max_actions: 3,
            max_conditions: 6
        }
    },

    computed: {
        selected_conditions_in_line: {
            get() {
                return this.selected_conditions
            },
            set(value) {
                this.$emit('update:selected_conditions', value)
            }
        },

        selected_actions_in_line: {
            get() {
                return this.selected_actions
            },
            set(value) {
                this.$emit('update:selected_actions', value)
            }
        },

        list_selected_subject_type() {
            return this.selected_conditions_in_line.map(
                (item) => item.subject_type
            )
        }
    },

    methods: {
        handleRemoveConditions(index) {
            this.selected_conditions_in_line.splice(index, 1)
        },

        handleRemoveActions(index) {
            this.selected_actions_in_line.splice(index, 1)
        },

        handleAddCondition() {
            if (
                this.selected_conditions_in_line.length === this.max_conditions
            ) {
                return
            }

            const subject_type = ''

            // for (const iterator of [
            //     'DAILY_BUDGET_SPENDING_RATE',
            //     'LIFETIME_BUDGET_SPENDING_RATE',
            //     'NAME',
            //     'DAYS_SINCE_CREATION'
            // ]) {
            //     if (
            //         this.selected_conditions_in_line.some(
            //             (item) => item.subject_type === iterator
            //         )
            //     ) {
            //         continue
            //     } else {
            //         subject_type = iterator
            //         break
            //     }
            // }

            this.selected_conditions_in_line.push({
                subject_type,
                range_type: 'TODAY',
                calculation_type: 'OF_EACH_OBJECT',
                match_type: 'GT',
                values: ['1']
            })
        },

        handleAddAction() {
            if (this.selected_actions_in_line.length === this.max_actions) {
                return
            }

            let subject_type = ''

            for (const iterator of ['TURN_ON', 'TURN_OFF', 'MESSAGE']) {
                if (
                    this.selected_actions_in_line.some(
                        (item) => item.subject_type === iterator
                    )
                ) {
                    continue
                } else {
                    subject_type = iterator
                    break
                }
            }

            this.selected_actions_in_line.push({
                subject_type,
                action_type: 'ADJUST_TO',
                value_type: 'EXACT',
                time_end: '',
                value: {
                    use_limit: false,
                    value: 0,
                    limit: 0
                },
                frequency_info: {
                    type: 'ONLY_ONCE',
                    custom_frequency_type: 'N_MINTUE_Y_TIMES',
                    time: 0,
                    count: 0
                }
            })
        }
    }
}
</script>
