<template>
    <div>
        <p class="mt-4 text-sm mb-1">
            {{ $t('page.campaign.run_schedule') }}
        </p>

        <el-radio-group
            v-model="rule_exec_info_inline.exec_time_type"
            class="block"
        >
            <el-radio label="QUARTER_HOUR">
                {{ $t('page.campaign.run_every_15_minutes') }}
            </el-radio>
        </el-radio-group>

        <el-radio-group
            v-model="rule_exec_info_inline.exec_time_type"
            class="block mt-4"
        >
            <el-radio label="PER_HALF_HOUR">
                {{ $t('page.campaign.run_every_30_minutes') }}
            </el-radio>
        </el-radio-group>

        <el-radio-group
            v-model="rule_exec_info_inline.exec_time_type"
            class="block mt-4"
        >
            <el-radio label="CUSTOM">
                {{ $t('page.campaign.run_daily') }}
            </el-radio>
        </el-radio-group>
        <div
            v-if="rule_exec_info_inline.exec_time_type === 'CUSTOM'"
            class="bg-bg p-4 mt-2"
        >
            <p class="text-xs">
                {{ $t('page.campaign.time_of_action') }}
            </p>

            <el-select
                v-model="rule_exec_info_inline.exec_time"
                class="mt-1 w-[320px]"
                size="small"
                placeholder="Select"
            >
                <el-option
                    v-for="item in time_of_action_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
        </div>

        <el-radio-group
            v-model="rule_exec_info_inline.exec_time_type"
            class="block mt-4"
        >
            <el-radio label="HALF_HOUR_IN_SPECIFIC_TIME_PERIOD">
                {{ $t('page.campaign.run_at_specific_times') }}
            </el-radio>
        </el-radio-group>
        <p class="text-desc-text text-xs ml-[27px] mt-1">
            {{ $t('page.campaign.run_rule_every_30_minute') }}
        </p>
        <div
            v-if="
                rule_exec_info_inline.exec_time_type ===
                'HALF_HOUR_IN_SPECIFIC_TIME_PERIOD'
            "
            class="bg-bg p-4 mt-2"
        >
            <p class="text-xs">
                {{ $t('page.campaign.time_of_action') }}
            </p>

            <div class="flex flex-col space-y-4">
                <el-row
                    v-for="(
                        time_period, index
                    ) of rule_exec_info_inline.time_period_info"
                    :key="index"
                    :gutter="12"
                >
                    <el-col :span="9">
                        <el-select
                            v-model="time_period.num"
                            size="small"
                            multiple
                            class="w-full"
                        >
                            <el-option
                                v-for="item in day_action_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="3">
                        <el-select
                            v-model="time_period.start_time"
                            size="small"
                            class="w-full"
                            placeholder="Select"
                        >
                            <el-option
                                v-for="item in ranger_start_time_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-col>

                    <el-col :span="3">
                        <el-select
                            v-model="time_period.end_time"
                            size="small"
                            class="w-full"
                            placeholder="Select"
                        >
                            <el-option
                                v-for="item in ranger_end_time_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-col>

                    <el-col :span="2">
                        <i
                            v-if="
                                rule_exec_info_inline.time_period_info.length >
                                1
                            "
                            class="el-icon-delete mt-2 cursor-pointer"
                            @click="handleRemoveTime(index)"
                        ></i>
                    </el-col>
                </el-row>
            </div>

            <el-button
                icon="el-icon-plus"
                type="text"
                :disabled="
                    rule_exec_info_inline.time_period_info.length ===
                    max_rule_exec_info
                "
                @click="handleAddTime()"
            >
                {{ $t('page.campaign.add_time') }} ({{
                    rule_exec_info_inline.time_period_info.length
                }}
                / {{ max_rule_exec_info }})
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['rule_exec_info'],

    data() {
        return {
            max_rule_exec_info: 7,
            time_of_action_options: [],
            ranger_start_time_options: [],
            ranger_end_time_options: [],
            day_action_options: [
                {
                    label: this.$t('common.monday'),
                    value: '1'
                },
                {
                    label: this.$t('common.tuesday'),
                    value: '2'
                },
                {
                    label: this.$t('common.wednesday'),
                    value: '3'
                },
                {
                    label: this.$t('common.thursday'),
                    value: '4'
                },
                {
                    label: this.$t('common.friday'),
                    value: '5'
                },
                {
                    label: this.$t('common.saturday'),
                    value: '6'
                },
                {
                    label: this.$t('common.sunday'),
                    value: '7'
                }
            ]
        }
    },

    computed: {
        rule_exec_info_inline: {
            get: function () {
                return this.rule_exec_info
            },
            set: function (val) {
                this.$emit('update:rule_exec_info', val)
            }
        }
    },

    watch: {
        'rule_exec_info_inline.exec_time_type'() {
            if (this.rule_exec_info_inline.exec_time_type === 'PER_HALF_HOUR') {
                this.rule_exec_info_inline.exec_time = ''
                this.rule_exec_info_inline.time_period_info = []
                return
            }
            if (this.rule_exec_info_inline.exec_time_type === 'CUSTOM') {
                this.rule_exec_info_inline.exec_time =
                    this.time_of_action_options[0].value
                this.rule_exec_info_inline.time_period_info = []
                return
            }
            if (
                this.rule_exec_info_inline.exec_time_type ===
                'HALF_HOUR_IN_SPECIFIC_TIME_PERIOD'
            ) {
                this.rule_exec_info_inline.exec_time = ''
                this.rule_exec_info_inline.time_period_info = [
                    {
                        num: [],
                        start_time: this.ranger_start_time_options[0].value,
                        end_time: this.ranger_end_time_options[0].value,
                        date_type: 'WEEK'
                    }
                ]
                return
            }
        }
    },

    mounted() {
        this.renderTimeOfActionOptions()

        this.renderRangeDatetimeOptions()
    },

    methods: {
        renderTimeOfActionOptions() {
            const temp = []

            for (let index = 0; index < 24; index++) {
                let hour = `0${index}`
                if (index >= 10) {
                    hour = index
                }

                let value = `${hour}:00`
                temp.push({
                    label: value,
                    value
                })

                value = `${hour}:30`
                temp.push({
                    label: value,
                    value
                })
            }

            this.time_of_action_options = temp
        },

        renderRangeDatetimeOptions() {
            const temp = []

            for (let index = 0; index < 24; index++) {
                let hour = `0${index}`
                if (index >= 10) {
                    hour = index
                }

                let value = `${hour}:00`
                temp.push({
                    label: value,
                    value
                })

                value = `${hour}:30`
                temp.push({
                    label: value,
                    value
                })
            }

            this.ranger_start_time_options = temp
            this.ranger_end_time_options = temp
        },

        handleRemoveTime(index) {
            this.rule_exec_info_inline.time_period_info.splice(index, 1)
        },

        handleAddTime() {
            this.rule_exec_info_inline.time_period_info.push({
                num: [],
                start_time: this.ranger_start_time_options[0].value,
                end_time: this.ranger_end_time_options[0].value,
                date_type: 'WEEK'
            })
        }
    }
}
</script>
